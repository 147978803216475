<template>
    <div>
        <span v-for="(it, id) in swal.scolor" :key="id">
        <v-alert dense v-if="it=='success'" :type="it" dismissible>
            <ul v-for="(item, index) in swal.message" :key="index">
                <li>{{item}}</li>
            </ul>
        </v-alert>
        <v-alert dense v-if="it=='error'" :type="it" dismissible>
            <ul v-for="(item, index) in swal.emessage" :key="index">
                <li>{{item}}</li>
            </ul>
        </v-alert>
        </span>
        <v-breadcrumbs :items="crumbs" divider="/" style="background-color: #f0f0f0">
        </v-breadcrumbs>
        <v-card class="mx-auto" outlined tile color="#F8F8FF">
        <v-card-title>Bays</v-card-title>
        <v-container class="fill-height"
        fluid>
            <v-row>
                <v-btn-toggle class="col-12 col-md-6 offset-md-6" style="background: none">
                    <v-btn small disabled>
                        <v-icon small>fa fa-search</v-icon>
                    </v-btn>
                    <v-text-field placeholder="Search" class="search-textfield" hide-details outlined dense v-model="search"></v-text-field>
                </v-btn-toggle>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Operator"
                            label="Operator"
                            v-model="operator.selected"
                            @change="execFilter('operator')"
                            :items="operator.items"
                            item-text="name"
                            item-value="OperatorID"
                            :rules="operator.rule"
                            :loading="operator.loader"
                            :disabled="operator.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                            dense
                            outlined
                            placeholder="All Car Park"
                            label="Car Park"
                            v-model="carpark.selected"
                            @change="execFilter('carpark')"
                            :items="carpark.items"
                            item-text="name"
                            item-value="CarParkID"
                            :rules="carpark.rule"
                            :loading="carpark.loader"
                            :disabled="carpark.disable"
                            hide-details
                            >
                            </v-autocomplete>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-pagination
                    v-model="page"
                    :length.sync="pages"
                    :total-visible="6"
                    class="mt-4"
                    style="float: left !important"
                    ></v-pagination><br/><p class="text-caption" style="margin-left: 10px">Page {{page}} of {{pages}}</p>
                </v-col>
                <v-col cols="12" md="6"><v-btn v-if="validateAccess('bay_add')" :loading="btn_loading" :disabled="btn_loading" tile type="submit" small color="primary" @click="viewMore(1, 'create')"><v-icon left x-small dark>fa fa-cogs</v-icon>Create Bay
                    <template v-slot:loader>
                        <span>Loading...</span>
                    </template>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col><span class="text-body-2">View {{page > 1 ? (page - 1) * 100 + 1 : 1}} - {{getCount}} of {{totalrecords}}</span></v-col>
                <v-col cols="12" md="12">
                    <v-data-table
                        calculate-widths
                        :headers="headers"
                        :items="items"
                        hide-default-footer
                        :loading="tableloading"
                        loading-text= "Loading Data... Please wait"
                        :items-per-page="100"
                        id="main-table"
                        dense
                    >
                    <template slot="item" slot-scope="props">
                        <tr>
                            <td>{{page > 1 ? (page - 1) * 100 + props.index + 1 : props.index + 1}}</td>
                            <td>{{props.item.CarParkID}}</td>
                            <td>{{props.item.CarParkUserInputCode}}</td>
                            <td style="white-space: pre">{{props.item.CarPark}}</td>
                            <td>{{props.item.BayOperatorCode}}</td>
                            <td>
                                <v-tooltip top v-if="validateAccess('bay_update')">
                                    <template v-slot:activator="{ on }">
                                        <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'update')">{{props.item.BayID}}</a>
                                    </template>
                                    <span>Update Bay</span>
                                </v-tooltip>
                                <v-tooltip top v-else>
                                    <template v-slot:activator="{ on }">
                                        <a v-on="on" style="text-decoration: underline" id="custom-hover" @click.prevent="viewMore(props.item, 'view')">{{props.item.BayID}}</a>
                                    </template>
                                    <span>View Bay</span>
                                </v-tooltip>
                            </td>
                            <td>{{props.item.CarParkBayPrefix}}</td>
                            <td>{{props.item.CarParkBayNo}}</td>
                            <td>{{props.item.BayNameID}}</td>
                            <td>{{props.item.WLWheelLockID}}</td>
                            <td>{{props.item.WLWheelLockRef}}</td>
                            <td>{{props.item.BayType}}</td>
                            <td>{{props.item.BayProductType}}</td>
                            <td>{{props.item.CreatedDateTime}}</td>
                            
                            <!-- <td>{{statusCheck(props.item.WLCarPresentStatus)}}</td> -->
                            <!--
                            <td v-if="validateAccess('bay_delete')">
                                <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                <span>
                                    <a v-on="on" id="custom-hover" @click.prevent="viewMore(props.item, 'delete')"><v-icon color="red" right x-small>fa fa-trash</v-icon></a>
                                </span>
                                </template>
                                <span>Delete Bay</span>
                                </v-tooltip>
                            </td>
                            -->
                        </tr>
                    </template>
                    </v-data-table>
                </v-col>
            </v-row>
        </v-container>
        </v-card>
        <!--
        <v-snackbar
        v-model="swal.notification"
        top
        :color="swal.scolor"
        >
            <ul v-for="(item, index) in swal.message" :key="index">
                <li>{{item}}</li>
            </ul>
        </v-snackbar>
        -->
        <create :dialog="ma.modal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></create>
        <edit :dialog="ma.modal_update" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :readonly="ma.readonly" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @delete="onDelete" @confirm="onConfirm"
         @closed="onClosed"></edit>
        <addbays :dialog="ma.modal_addbays" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></addbays>
        <removebays :dialog="ma.modal_removebays" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></removebays>
        <createmapping :dialog="ma.modal_mapping" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" :allInfo="ma.allInfo" @confirm="onConfirm" @closed="onClosed"></createmapping>
        <alert :dialog="ma.modal_status" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alert>
        <alertbox2 :dialog="ex.modal" @closed="onClosed" :content="ex.content"></alertbox2>
    </div>
</template>
<script>
import timer from '../../assets/js/sleeper';
import create from '.././modals/CreateBay';
import edit from '.././modals/EditBay';
import createmapping from '.././modals/CreateCarparkMapping';
import addbays from '.././modals/AddBays';
import removebays from '.././modals/RemoveBays';
import alert from '.././modals/Alert';
import {ERR} from '../../assets/js/errhandle.js';
import alertbox2 from '../modals/Notification';
import * as moment from "moment";
import * as tz from "moment-timezone";
import {rule} from '../../assets/js/checkPerm';
import * as AppStorage from '../../assets/js/storage';

export default {
    name: 'Bays',
    title: 'Bays',
    
    data()  {
        return {
            crumbs: [
                {
                text: 'Home',
                disabled: true,
                href: '#',
                },

                {
                text: 'Car Park',
                disabled: true,
                href: '#',
                },

                {
                text: 'Bays',
                disabled: true,
                href: '#',
                },
            ],
            items: [],
            search: '',
            /*
            #, CP ID, CP Code, Car Park, Bay CPO Code, Bay ID, CP Prefix, Bay Number, Bay Name, Bay Type, Product Type, Created Date, WLock ID, WLock Ref., Car Present
            */
            headers: [
                { text: '#', value: 'row', width: '1%' },
                { text: 'CP ID', value: 'CarPark', width: '2%' },
                { text: 'CP Code', value: 'CarPark', width: '2%' },
                { text: 'Car Park', value: 'CarPark', width: '3%' },
                { text: 'Bay CPO Code', value: 'BayOperatorCode', width: '3%' },
                { text: 'Bay ID', value: 'BayID', width: '3%' },
                { text: 'CP Prefix', value: 'BayNameID', width: '5%' },
                { text: 'Bay Number', value: 'BayNameID', width: '5%' },
                { text: 'Bay Name', value: 'BayNameID', width: '5%' },
                { text: 'BL ID', value: 'BayType', width: '5%' },
                { text: 'BL Ref.', value: 'BayType', width: '5%' },
                { text: 'Bay Type', value: 'BayType', width: '5%' },
                { text: 'Product Type', value: 'BayProductType', width: '5%' },
                { text: 'Created Date', value: 'CreatedDateTime', width: '10%', sort: function (a, b) {
                    let aa = moment(a, 'DD-MM-YYYY hh:mm:ss A');
                    let aa2 = aa.toDate();

                    let bb = moment(b, 'DD-MM-YYYY hh:mm:ss A');
                    let bb2 = bb.toDate();

                    return aa2 > bb2 ? -1 : aa2 < bb2 ? 1 : 0;
                }  },
                
            ],
            tableloading: false,
            btn_loading: false,
            pages: 0,
            page: 1,
            swal: {
                notification: false,
                message: [],
                emessage: [],
                scolor: [],
            },
            swal2: {
                notification: false,
                message: [],
                scolor: [],
            },
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                confirmCreate: false,

                modal_status: false,
                modal_update: false,
                modal_mapping: false,
                readonly: true,
                allInfo: [],

                modal_addbays: false,
                modal_removebays: false
            },

            ex: {
                modal: false,
                content: {
                    headline: '',
                    icon: '',
                    chipColor: '',
                    iconColor: '',
                    maintext: '',
                    smalltext: ''
                }
            },
            system: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            operator: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },
            carpark: {
                items: [],
                itemsFull: [],
                selected: '',
                loader: false,
                disable: false,
                rule: []
            },

            services: [],
            totalrecords: ''
        }
    },

    components: {create, edit, alert, alertbox2, createmapping, addbays, removebays},

    async created(){
        await this.getOperatorFull();
        await this.getCarParkFull();
        window.addEventListener("unload", (evt) => this.onUnload({system: this.system.selected, operator: this.operator.selected, carpark: this.carpark.selected}, evt));
        this.system.selected = JSON.parse(AppStorage.get('filter')).system;
        this.operator.selected = JSON.parse(AppStorage.get('filter')).operator;
        this.carpark.selected = JSON.parse(AppStorage.get('filter')).carpark;
        this.getList(1);
    },

    watch: {
        page: {
        handler: function(value) {
            this.items = [];
            this.getList();
        }
        },

        'search': {
        handler: function(value) {
            this.searchTimeOut();
        }
        },
    },

    computed: {
        getCount(){
            if (this.items.length > 0)
                return this.items[this.items.length - 1].row;

                else{
                    return '';
                }
            },
    },

    methods: {
        async getList(pg){
            this.items = [];
            try{
                this.tableloading = true;
                let response4 = await this.$store.dispatch("getAllCarpark2", {});
                let response = await this.$store.dispatch("viewAllCarParkBay", {search: this.search, systemid: this.system.selected, operatorid: this.operator.selected, carparkid: this.carpark.selected, resultpage: pg > 0 ? this.page = pg : this.page, sortingcolumn: 'BayID'});

                this.services = response;
                if (response.data.code == 'AP000'){
                let dt = response.data.record;
                let recordCount = response.data.recordcount;
                this.totalrecords = recordCount;

                for(var i=0; i<dt.length; i++){
                    let cp = response4.data.record.find(item => item.CarParkID == dt[i].CarParkID);
                    dt[i].row = this.page > 1 ? (this.page - 1) * 100 + i + 1 : i + 1;
                    dt[i].CreatedDateTime = moment(new Date(dt[i].CreatedDateTime)).format('DD-MM-YYYY hh:mm:ss A');
                    dt[i].UpdatedDateTime = dt[i].UpdatedDateTime ? moment(new Date(dt[i].UpdatedDateTime)).format('DD-MM-YYYY hh:mm:ss A') : "N/A";
                    dt[i].BayNameID = dt[i].BayNameID == null ? "N/A" : dt[i].BayNameID;
                    dt[i].CarPark = cp.CarParkName || "N/A";
                    dt[i].CarParkUserInputCode = cp.CarParkUserInputCode || "N/A";
                    dt[i].BayOperatorCode = dt[i].BayOperatorCode || "N/A";
                    dt[i].WLWheelLockID = dt[i].WLWheelLockID || "N/A";
                    dt[i].WLWheelLockRef = dt[i].WLWheelLockRef || "N/A";
                    dt[i].BayType = dt[i].BayType || "N/A";
                    dt[i].BayProductType = dt[i].BayProductType || "N/A";

                    //replace(/\s/g,'') remove any literal whitespace
                }

                this.items = dt;
                this.pages = Math.ceil(recordCount / 100);
                this.tableloading = false;
                }
            }

            catch(err){
                console.log(err);
            }
        },

        async getOperatorFull(){
            let list = [];
            this.operator.items = [];
            this.operator.selected = "";

            try{
                this.operator.loader = true;
                this.operator.disable = true;

                let self = this;
                if (this.system.selected){
                    let operator = this.operator.itemsFull.filter(item => {
                    return item.SystemID == self.system.selected;
                    });

                    this.operator.items.push({name: "All Operator", OperatorID: ""});

                    for(var i=0; i<operator.length; i++){
                        operator[i].name = `${operator[i].OperatorID} - ${operator[i].OperatorName}`;
                        this.operator.items.push({name: operator[i].name, OperatorID: operator[i].OperatorID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllOperator2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.operator.items.push({name: "All Operator", OperatorID: ""});
                        this.operator.itemsFull.push({name: "All Operator", OperatorID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 1) continue;

                        dt[i].name = `${dt[i].OperatorID} - ${dt[i].OperatorName}`;
                        this.operator.items.push({name: dt[i].name, OperatorID: dt[i].OperatorID});
                        this.operator.itemsFull.push(dt[i]);
                    }

                    this.operator.selected = this.operator.items[0].OperatorID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.operator.loader = false;
                this.operator.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        async getCarParkFull(){
            let list = [];
            this.carpark.items = [];
            this.carpark.selected = "";

            try{
                this.carpark.loader = true;
                this.carpark.disable = true;

                let self = this;
                if (this.system.selected && !this.operator.selected){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.SystemID == self.system.selected;
                    });

                    this.carpark.items.push({name: "All Car Park", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else if ((!this.system.selected && this.operator.selected) || (this.system.selected && this.operator.selected)){
                    let carpark = this.carpark.itemsFull.filter(item => {
                    return item.OperatorID == self.operator.selected;
                    });

                    this.carpark.items.push({name: "All Car Park", CarParkID: ""});

                    for(var i=0; i<carpark.length; i++){
                        carpark[i].name = `${carpark[i].CarParkID} - ${carpark[i].CarParkName}`;
                        this.carpark.items.push({name: carpark[i].name, CarParkID: carpark[i].CarParkID});
                    }

                    //this.operator.selected = this.operator.items[1].OperatorID;
                }

                else{
                    let response = await this.$store.dispatch("getAllCarpark2", {});
                    let dt = response.data.record;
                    if (response.data.code == 'AP000'){
                        this.carpark.items.push({name: "All Car Park", CarParkID: ""});
                        this.carpark.itemsFull.push({name: "All Car Park", CarParkID: ""});

                    for(var i=0; i<dt.length; i++){
                        if (dt[i].SystemID === 1) continue;
                        
                        dt[i].name = `${dt[i].CarParkID} - ${dt[i].CarParkName}`;
                        this.carpark.items.push({name: dt[i].name, CarParkID: dt[i].CarParkID});
                        this.carpark.itemsFull.push(dt[i]);
                    }

                    this.carpark.selected = this.carpark.items[0].CarParkID;
                    }
                }

                //this.operator.itemsFull = dt;

                this.carpark.loader = false;
                this.carpark.disable = false;
            }

            catch(err){
                console.log(err);
            }

            return list;
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async addBay(v){
            
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("createBays", {carparkid: v[2].selected, baynameid: v[3].bayname, baytype: v[4].selected, bayproducttype: v[5].selected, baylatitude: v[6].baylatitude, baylongitude: v[7].baylongitude, bayno: v[8].bayno, bayoperatorcode: v[9].baycpocode})
                if (response.data.code === 'AP000'){
                        await this.getList();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Bay has been created!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to create.`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push('Something went wrong. Please contact tech team');
                this.tableloading = false;
            }
        },

        async editBay(v){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("editBays", {carparkid: this.ma.modalInfo.CarParkID, carparkbayid: this.ma.modalInfo.BayID, baynameid: v[3].bayname, baytype: v[4].selected, bayproducttype: v[5].selected, baylatitude: v[6].baylatitude, baylongitude: v[7].baylongitude, bayoperatorcode: v[8].baycpocode, bayno: v[9].baynumber})
                if (response.data.code === 'AP000'){
                        await this.getList();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Bay has been updated!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to update.`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push('Something went wrong. Please contact tech team');
                this.tableloading = false;
            }
        },

        async removeBay(){
            try{
                this.tableloading = true;
                let response = await this.$store.dispatch("removeBays", {carparkid: this.ma.modalInfo.CarParkID, carparkbayid: this.ma.modalInfo.BayID})
                if (response.data.code === 'AP000'){
                        await this.getList();

                        this.swal.notification = true;
                        this.handleSnackbarMessage('success');
                        this.swal.message.push( `${ERR.HANDLE(response.data.code)}: Bay has been deleted!`);
                }

                else if (response.data.code === 'RD002'){
                        this.swal.notification = true;
                        this.handleSnackbarMessage('error');
                        this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}: Unable to delete.`);
                }

                else{
                    this.loading = false;
                    this.swal.notification = true;
                    this.handleSnackbarMessage('error');
                    this.swal.emessage.push( `${ERR.HANDLE(response.data.code)}`);
                }

                this.tableloading = false;
            }

            catch(err){
                this.handleSnackbarMessage('error');
                this.swal.notification = true;
                this.swal.emessage.push('Something went wrong. Please contact tech team');
                this.tableloading = false;
            }
        },

        async handleSnackbarMessage(type, message){
            if (type == 'success'){
                if (!this.swal.scolor.includes('success')){
                    this.swal.scolor.push('success');
                }
            }

            if (type == 'error'){
                if (!this.swal.scolor.includes('error')){
                    this.swal.scolor.push('error');
                }
            }

            await timer.sleep(4000);
            this.swal.scolor = [];
        },

        viewMore(row, flag){
            //this.autoRefresh = 0;
            if (flag == 'delete'){
                this.ma.modal_status = true;
                this.ma.modalInfo = row;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
            }

            if (flag == 'create'){
                this.ma.allInfo = [];
                this.ma.modal = true;
                this.ma.allInfo.push({items: this.items, operator: this.operator.itemsFull, carpark: this.carpark.itemsFull});
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
            }

            if (flag == 'update'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
                this.ma.readonly = false;
            }

            if (flag == 'view'){
                this.ma.modal_update = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
                this.ma.readonly = true;
            }

            if (flag == 'add'){
                this.ma.modal_addbays = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
            }

            if (flag == 'remove'){
                this.ma.modal_removebays = true;
                this.ma.modalInfo = row;
                this.ma.allInfo = this.items;
                this.swal.message = [];
                this.swal.emessage = [];
                this.swal.scolor = [];
                this.swal.notification = false;
            }

            this.ma.customText = {text1: "Gate ID", text2: "Name"};
            this.ma.modal_action_type = flag;
        },

        onClosed(value){
            console.log(value)
            if (value == false){
                this.ma.modal = value;
                this.ma.modal_status = value;
                this.ma.modal_update = value;
                this.ma.modal_mapping = value;
                this.ex.modal = value;
                this.ma.modal_addbays = value;
                this.ma.modal_removebays = value;
            }
        },

        async onConfirm(value){
            //console.log(value)
            if (value.flag === 'create'){
                this.ma.confirmCreate = true;
                this.ma.modal = false;
                console.log(value);
                this.addBay(value);
            }

            if (value.flag === 'update'){
                this.ma.modal_update = false;
                this.editBay(value);
            }

            if (value.flag === 'addbay'){
                this.ma.confirmCreate = true;
                this.ma.modal_addbays = false;

                let items = JSON.parse(JSON.stringify(value));

                for(let [index, item] of items.entries()){
                    let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                    let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;

                    let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, baynameid: baynameid, baytype: baytype, baylat: baylat, baylong: baylong};
                    console.log('itemsforapi', itemsforapi);
                    await this.addBay(itemsforapi);
                }

                await this.getList();
            }

            if (value.flag === 'editbay'){
                this.ma.confirmCreate = true;
                this.ma.modal_addbays = false;

                let newitems = JSON.parse(JSON.stringify(value.newArr));
                let olditems = JSON.parse(JSON.stringify(value.oldArr));
                console.log('new', newitems);
                console.log('old', olditems);

                let baynameidtruth = 0; let baytypetruth = 0;
                let baylattruth = 0; let baylongtruth = 0;

                for(let [index, item] of olditems.entries()){
                    let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                    let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;

                    baynameidtruth += item[1].baynameid == this.ma.modalInfo.Bays[index].BayNameID ? 0 : 1;
                    baytypetruth += item[2].baytype == this.ma.modalInfo.Bays[index].BayType ? 0 : 1;
                    baylattruth += item[3].baylatitude == this.ma.modalInfo.Bays[index].BayLatitude ? 0 : 1;
                    baylongtruth += item[4].baylongitude == this.ma.modalInfo.Bays[index].BayLongitude ? 0 : 1;
                }

                let checkifoldvalueschanged = baynameidtruth + baytypetruth + baylattruth + baylongtruth;

                if (checkifoldvalueschanged > 0){
                    for(let [index, item] of olditems.entries()){
                        let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                        let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;
                        let bayid = item[5].bayid;

                        let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, baynameid: baynameid, baytype: baytype, baylat: baylat, baylong: baylong, bayid: bayid};
                        await this.editBay(itemsforapi);
                    }
                }

                for(let [index, item] of newitems.entries()){
                    let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                    let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;
                    let bayid = item[5].bayid;

                    let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, baynameid: baynameid, baytype: baytype, baylat: baylat, baylong: baylong, bayid: bayid};
                    await this.addBay(itemsforapi);
                }

                await this.getList();

                /*
                for(let [index, item] of items.entries()){
                    let baynameid = item[1].baynameid; let baytype = item[2].baytype;
                    let baylat = item[3].baylatitude; let baylong = item[4].baylongitude;

                    let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, baynameid: baynameid, baytype: baytype, baylat: baylat, baylong: baylong};
                    console.log('itemsforapi', itemsforapi);
                    this.addBay(itemsforapi);
                }
                */
            }

            if (value === true){
                if (this.ma.modal_action_type == 'delete')
                {
                    this.ma.modal_status = false;
                    this.removeBay();
                }
            }

            if (value.flag === 'removebay'){
                this.ma.confirmCreate = true;
                this.ma.modal_removebays = false;
                
                let items = JSON.parse(JSON.stringify(value));

                for(let [index, item] of items.entries()){
                    let bayid = item[5].bayid;
                    let rmItem = item[6].rmItem;

                    if (rmItem == true){
                        let itemsforapi = {carparkid: this.ma.modalInfo.CarParkID, bayid: bayid};
                        await this.removeBay(itemsforapi);
                    }
                }

                await this.getList();
            }
        },

        onDelete(){
            this.ma.modal_update = false;
            this.removeBay();
        },

        async execFilter(type){
            if (type == 'operator'){
                await this.getCarParkFull();
                await this.searchTimeOut();
            }

            if (type == 'carpark'){
                await this.searchTimeOut();
            }
        },

        searchTimeOut() {

          clearTimeout(this.timeout);

          // Make a new timeout set to go off in 800ms
          this.timeout = setTimeout(() => {
          this.getList(1);
          }, 800);
        },
        
        isObjEmpty(obj) {
        for(var prop in obj) {
            if(obj.hasOwnProperty(prop)) {
            return false;
            }
        }

        return JSON.stringify(obj) === JSON.stringify({});
        },

        validateArr(arr){
            let truth = arr.some(item => {
                return item === true;
            });

            return truth;
        },

        onUnload(filter, evt){
            AppStorage.set('filter', JSON.stringify(filter));
        },

        statusCheck(val){
            if (val === 0){ return 'No'}
            else if (val === 1){return 'Yes'}
            else {return 'N/A'}
        },
    }
}
</script>