<template>
<v-row justify="center">
<v-dialog v-model="dialog_local" max-width="650">
    <v-form ref="ct" action="#" @submit.prevent="beforeCreateForm('update')" lazy-validation>
        <v-card class="mx-auto" tile>
        <v-card-title class="justify-space-between" style="background-color: #3D348B; color: white; padding-top: 5px; padding-bottom: 5px">
            {{readonly ? 'View' : 'Update'}} Bay
            <v-btn text @click="$emit('closed', false)" fab depressed small><v-icon small color="white">fas fa-times</v-icon></v-btn>
            </v-card-title>
        <v-card-text>
        <v-container class="fill-height"
        fluid >
            <v-row>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.CarParkID"
                        label="CP ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.CarPark"
                        label="Car Park"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[8].baycpocode"
                        :rules="form[8].rule"
                        label="Bay CPO Code"
                        required
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.BayID"
                        label="Bay ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.CarParkBayPrefix"
                        label="CP Prefix"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[9].baynumber"
                        :rules="form[9].rule"
                        label="Bay Number *"
                        required
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        v-model="form[3].bayname"
                        :rules="form[3].rule"
                        label="Bay Name *"
                        required
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[4].selected"
                        :items="form[4].baytype"
                        :rules="form[4].rule"
                        item-text="name"
                        item-value="id"
                        label="Bay Type *"
                        outlined
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" md="12">
                    <v-autocomplete
                        dense
                        v-model="form[5].selected"
                        :items="form[5].bayproducttype"
                        :rules="form[5].rule"
                        item-text="name"
                        item-value="id"
                        label="Product Type *"
                        outlined
                        :disabled="readonly"
                        hide-details="auto"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                            dense
                                            outlined
                                            v-model="form[6].baylatitude"
                                            @change="setPlaceFromAPI(form[6].baylatitude, form[7].baylongitude)"
                                            :rules="form[6].rule"
                                            label="Bay Latitude"
                                            hide-details="auto"
                                        ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field background-color="white"
                                        dense
                                        outlined
                                        v-model="form[7].baylongitude"
                                        @change="setPlaceFromAPI(form[6].baylatitude, form[7].baylongitude)"
                                        :rules="form[7].rule"
                                        label="Bay Longitude"
                                        hide-details="auto"
                                    ></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="12">
                                        <gmap-autocomplete
                                        v-model="this.searchlocation"
                                        :class="[`search-textfield v-input__slot border-vc`]"
                                        style="background-color: white"
                                        placeholder="Search Location"
                                        @place_changed="setPlace">
                                        </gmap-autocomplete>
                                        <GmapMap
                                        v-if="componentloader"
                                        :center="mapCenter"
                                        :zoom="zoom"
                                        style="width: 100%; height: 300px"
                                        >
                                        <GmapMarker
                                            :position="markers.position"
                                            :clickable="true"
                                            :draggable="true"
                                            @dragend="gMapFunc($event.latLng)"
                                        />
                                        </GmapMap>
                                    </v-col>
                                    <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.CreatedDateTime"
                        label="Created Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.UpdatedDateTime"
                        label="Updated Date"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                                    <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.WLWheelLockID"
                        label="WLock ID"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.WLWheelLockRef"
                        label="WLock Ref."
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" md="12">
                    <v-text-field
                        dense
                        outlined
                        :value="modalInfo.WLCarPresentStatus == 0 ? 'No' : 'Yes'"
                        label="Car Present"
                        required
                        disabled
                        hide-details="auto"
                    ></v-text-field>
                </v-col> -->
            </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn v-if="!readonly" tile type="submit" small color="primary"><v-icon left x-small dark>fa fa-save</v-icon>Save</v-btn>
            <v-btn v-if="validateAccess('bay_delete')" tile color="error darken-1" small @click="handleDelete('delete')" class="ml-2">Delete</v-btn>
            <v-spacer></v-spacer>
            <v-btn tile color="blue darken-1" text @click="$emit('closed', false)">Close</v-btn>
        </v-card-actions>
        </v-card>
    </v-form>
    <alertbox :dialog="innerModal" :customText="ma.customText" :modal_action_type="ma.modal_action_type" :modalInfo="ma.modalInfo" @confirm="onConfirm" @closed="onClosed"></alertbox>
</v-dialog>
</v-row>
</template>
<script>
import alertbox from './Alert';
import {rule} from '../../assets/js/checkPerm';

export default {
    props: ['dialog', 'modalInfo', 'customText', 'allInfo', 'readonly'],
    
    data()  {
        return {
            dialog_local: false,
            innerModal: false,
            form: [ 
                {system: [], itemsFull: [], rule: [], selected: '' }, // 0
                {operator: [], itemsFull: [], rule: [], selected: '' }, // 1
                {carpark: [], itemsFull: [], rule: [], selected: '' }, // 2
                {bayname: '', rule: [] }, // 3
                {baytype: [], rule: [], selected: '' }, // 4
                {bayproducttype: [], rule: [], selected: '' }, //5
                {baylatitude: '', rule: [] }, // 6
                {baylongitude: '', rule: [] }, // 7
                {baycpocode: '', rule: [] }, // 8
                {baynumber: '', rule: [] }, // 9
            ],

            roles: [],
            ma: {
                modal: false,
                modal_action_type: '',
                modalInfo: [],
                customText: [],
                edit_modal: false,
                view_modal: false,
                confirmEdit: false,
                confirmCreate: false,
            },

            map: null,
            mapCenter: {lat: 5.502994, lng: 101.700439},
            markers: {
                position: {
                lat: 5.502994,
                lng: 101.700439
                },
            },

            coordinates: null,
            zoom: 9,
            searchlocation: '',
            componentloader: false
        }
    },

    components: {alertbox},

    watch: {
        dialog: {
        handler: async function(value) {
            this.modalHandler();
            if(value == true){
                this.handleValue();
            }
        },
        },

        dialog_local: {
        handler: function(value) {
            this.modalCloseHandler();
        },
        },

        'markers.position': {
        handler: function(value) {
            this.form[6].baylatitude = value.lat;
            this.form[7].baylongitude = value.lng;
        },
        },
    },

    methods: {
        async getAllSystem(){
            let response = await this.allInfo[0].system;
            this.form[0].system = response;
            this.form[0].itemsFull = response;
        },

        async getAllOperator(){
            let response = await this.allInfo[0].operator;
            this.form[1].operator = response;
            this.form[1].itemsFull = response;
        },

        async getAllCarPark(){
            let response = await this.allInfo[0].carpark;
            this.form[2].carpark = response;
            this.form[2].itemsFull = response;
        },

        handleDelete(value){
            this.innerModal = true;
            this.ma.modal_action_type = value;
        },

        execFilter(typ){
            let self = this;

            if (typ == 'system'){
                this.form[1].operator = [];
                this.form[1].selected = '';

                this.form[2].carpark = [];
                this.form[2].selected = '';

                if (self.form[0].selected){
                    let itm = this.form[1].itemsFull.filter(i => i.SystemID == self.form[0].selected);
                    let itmCP = [];
                    this.form[1].operator.push({name: 'Select Operator', OperatorID: ''});

                    for(let i=0; i<itm.length; i++){
                        this.form[1].operator.push({name: itm[i].name, OperatorID: itm[i].OperatorID});

                        let a = this.form[2].itemsFull.filter(j => j.OperatorID == itm[i].OperatorID); //filter carpark from operator id
                        itmCP.push(a);
                    }

                    let flattenedCP = itmCP.reduce(function(a,b){ return a.concat(b) }, []);
                    console.log('itmcp', flattenedCP);
                    this.form[2].carpark.push({name: 'Select Car Park', CarParkID: ''});
                    for(let i=0; i<flattenedCP.length; i++){
                        this.form[2].carpark.push({name: flattenedCP[i].name, CarParkID: flattenedCP[i].CarParkID});
                    }
                }

                else{
                    this.form[1].operator = this.form[1].itemsFull;
                    this.form[2].carpark = this.form[2].itemsFull;
                }
            }

            if (typ == 'operator'){
                this.form[2].carpark = [];
                this.form[2].selected = '';

                if (self.form[1].selected){
                    let itm = this.form[2].itemsFull.filter(i => i.OperatorID == self.form[1].selected);

                    this.form[2].carpark.push({name: 'Select Car Park', CarParkID: ''});

                    for(let i=0; i<itm.length; i++){
                        this.form[2].carpark.push({name: itm[i].name, CarParkID: itm[i].CarParkID});
                    }
                }

                else if (self.form[1].selected == ''){
                    this.execFilter('system');
                }

                else{
                    this.form[2].carpark = this.form[2].itemsFull;
                }
            }
        },

        async getAllBayType(){
            let response = [
                {name: 'Select', id: ''},
                {name: 'Normal', id: 'NORMAL'},
                {name: 'Season', id: 'SEASON'},
                {name: 'Book A Bay', id: 'BOOKABAY'}
            ];
            this.form[4].baytype = response;
            this.form[4].selected = "";
        },

        async getAllBayProductType(){
            let response = [
                {name: 'Select', id: ''},
                {name: 'Reserve', id: 'RESERVE'},
                {name: 'Non-Reserve', id: 'NONRESERVE'},
            ];
            this.form[5].bayproducttype = response;
            this.form[5].selected = "";
        },

        async checkRef(val){
            if (val != undefined){
                let system = await this.allInfo;
                let check = system.some((item) => {
                    return item.OperatorID.toLowerCase() == val.toLowerCase();
                });

                return check;
            }

            else{
                return '';
            }
        },

        onRuleChange() {
            this.form[0].rule = [];
            this.form[1].rule = [];
            this.form[2].rule = [];
            this.form[3].rule = [];
            this.form[4].rule = [];
            this.form[5].rule = [];
            this.form[6].rule = [];
            this.form[7].rule = [];
            this.form[8].rule = [];
            this.form[9].rule = [];
        },

        async beforeCreateForm(flag){
            this.form[2].rule = [
                v => !!v || 'This is required',
            ];

            this.form[3].rule = [
                v => !!v || 'This is required',
            ];

            this.form[4].rule = [
                v => !!v || 'This is required',
            ];

            this.form[5].rule = [
                v => !!v || 'This is required',
            ];

            this.form[6].rule = [
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            this.form[7].rule = [
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            this.form[9].rule = [
                v => !!v || 'This is required',
                v => !v || !isNaN(v) || 'Only number is allowed'
            ];

            //must set this in order to use 'this' as 'self' inside settimeout etc..
            let self = this;

            setTimeout(function () {
             if (self.$refs.ct.validate()) {
                    self.innerModal = true;
                    self.ma.modalInfo = {id: 1, name: 2};
                    self.ma.modal_action_type = flag;
                }
            });
        },

        modalHandler(){
            this.componentloader = true;
            this.searchlocation = "";
            this.dialog_local = !this.dialog_local;
        },

        modalCloseHandler(){
            if (this.dialog_local == false){
                this.componentloader = false;
                this.$emit('closed', false);
            }
        },

        validateAccess(val){
            let access = JSON.parse(JSON.stringify(this.$store.getters.getAccess))
            return rule.checkaccess(val, access);
        },

        async handleValue(){
            this.onRuleChange();
            this.getAllSystem();
            this.getAllOperator();
            this.getAllCarPark();
            this.getAllBayType();
            this.getAllBayProductType();

            this.form[0].selected = '';
            this.form[1].selected = '';
            this.form[2].selected = '';
            this.form[3].bayname = this.modalInfo.BayNameID;
            this.form[4].selected = this.modalInfo.BayType == null ? "" : this.modalInfo.BayType;
            this.form[5].selected = this.modalInfo.BayProductType == null ? "" : this.modalInfo.BayProductType;
            this.form[6].baylatitude = this.modalInfo.BayLatitude;
            this.form[7].baylongitude = this.modalInfo.BayLongitude;
            this.form[8].baycpocode = this.modalInfo.BayOperatorCode;
            this.form[9].baynumber = this.modalInfo.CarParkBayNo;
            this.setPlaceFromAPI(this.modalInfo.BayLatitude, this.modalInfo.BayLongitude);
        },

        //set the action to be sent to watcher to execute the create/edit function
        onConfirm(value){
          //console.log(value);
                if (this.ma.modal_action_type == 'update'){
                    this.form.flag = 'update';
                    this.$emit('confirm', this.form);
                }

                if (this.ma.modal_action_type == 'delete'){
                    this.form.flag = 'delete';
                    this.$emit('delete');
                }

                this.innerModal = false;
        },

        onClosed(value){
            if (value == false){
                this.innerModal = value;
            }
        },

        gMapFunc(evnt) {
            this.markers.position = {"lat":evnt.lat(), "lng":evnt.lng()};
        },

        setPlace(place) {
            if (!place) return
            console.log('t', place);
            this.searchlocation = place.formatted_address;
            this.zoom = 9;
            this.markers.position = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            };

            this.mapCenter = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            };

            this.zoom = 12;
        },

        setPlaceFromAPI(aLat, aLng) {
            this.searchlocation = '';
            if (!aLat) return

            this.zoom = 9;
            this.markers.position = {
            lat: Number(aLat),
            lng: Number(aLng),
            };

            this.mapCenter = {
            lat: Number(aLat),
            lng: Number(aLng),
            };

            this.zoom = 12;
        },

        initMap(){
            this.map = new google.maps.Map(document.getElementById('map'), {
            center: this.mapCenter,
            zoom: true,
            maxZoom: 20,
            minZoom: 3,
            streetViewControl: true,
            mapTypeControl: true,
            fullScreenControl: true,
            zoomControl: true
        });
        },
    }
}
</script>